.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-height: 80%; /* Set a maximum height for the modal content */
  overflow-y: auto; /* Allow vertical scrolling */
  position: relative; /* Position relative for absolute positioning */
}

.close-button {
  position: relative; /* Fixed position to keep it visible */
  top: 12%;
  left: 90%;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 1; /* Ensure the close button is above other content */
}

.results-list {
  list-style-type: none;
  padding: 0;
  margin-top: 40px; /* Adjust margin to accommodate close button */
}

.result-item {
  margin-bottom: 10px;
  border-bottom: 1px solid black; /* Add a black line separator */
  padding-bottom: 10px; /* Add padding to separate items visually */
}

/* Add your other styles as needed */
