.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the start */
  min-height: 100vh;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #5e6f91;
  min-height: 100vmin;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: auto;
  justify-content: center;
  font-size: calc(4px + 2vmin);
  color: rgb(15, 14, 14);
  position: relative;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  box-sizing: border-box;
}

.App-content {
  width: 100%; /* Adjust the width of the content */
  flex: 1;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .LeftMenu {
    width: 100%;
    min-width: 0;
  }

  .App {
    align-items: stretch;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sticky_tab_Nagata {
  color: #ff0000;
  background-color: #000000;
  min-height: fit-content;
  min-width: fit-content;
  font-size: 30px;
  position: fixed;
}

.close_left {
  margin-left: 0px;
}

.space_right {
  margin-right: 1px;
}

.Tab_nagata {
  color: #d50303;
  background-color: #000000;
  min-height: fit-content;
  min-width: 100%;
  font-size: 30px;
  display: flex;
  position: relative;
  margin-top: 0%;
  z-index: 9999; /* Set a high z-index value to ensure it appears above other elements */
}

.stay_left {
  margin-right: 10%;
  margin-top: -48%;
  position: absolute;
}

.stay_left_fg {
  margin-left: 20%;
  margin-top: -23%;
  position: absolute;
}

.stay_left_upload {
  margin-left: 1%;
  padding-top: 10px;
}
.stay_left_search {
  margin-left: 18%;
  margin-top: -41%;
  position: absolute;
}

.stay_right {
  margin-right: 0%;
}

.stay_right_date {
  margin-top: -15%;
  margin-left: 82%;
  position: absolute;
}

.table_relocate {
  margin-top: -38%;
  margin-left: 17%;
  color: #000;
  background-color: rgb(255, 255, 255);
  position: absolute;
  box-sizing: border-box;
}

.table_relocate_fg {
  margin-top: -40%;
  margin-left: 17%;
  color: #000;
  background-color: azure;
  position: relative;
}

.button_order_marketing {
  background-color: #fc9f14;
  color: #000;
  border: #000;
  margin-top: -5%;
  margin-left: -50%;
  position: relative;
  border-radius: 20px;
  min-height: fit-content;
  min-width: fit-content;
}
.button_export_marketing {
  background-color: #fc9f14;
  color: #000;
  border: #000;
  margin-top: -5%;
  margin-left: 5%;
  position: relative;
  border-radius: 20px;
  min-height: fit-content;
  min-width: fit-content;
}

.fg_bit_more {
  margin-top: 2%;
}

.link_locate_fixed {
  position: absolute;

  margin-left: 4%;
  font-size: large;
}

.menu-link {
  color: #f49f0e; /* default color */
  text-decoration: none;
}

.menu-link.active {
  display: none;
}

.box_form_input {
  background-color: white;
  width: 400px;
  padding: 20px;
  height: fit-content;
  margin-left: 38%;
  display: block;
}

.market_action {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  margin-left: -30%;
  margin-top: -2.5%;
}

.next_order {
  margin-left: 2%;
}

.prev_order {
  margin-right: 2%;
}

.table_background {
  background-color: rgb(255, 255, 255);
  margin-top: 2%;
  overflow-x: auto;
  overflow-y: auto;
}

.page_action {
  padding: 5px;
}

.custom-input {
  width: 50%; /* Adjust width to fit two inputs in a cell */
  margin: 0;
  /* Set the width of the input fields */
}
.custom-input-mainpart {
  width: 100%; /* Adjust width to fit two inputs in a cell */
  margin: 0;
  /* Set the width of the input fields */
}

.day-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.next_week_button {
  margin-left: 87.3%;
  margin-bottom: 10px;
  margin-right: 10px;
}

.input_container_planning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.highlighted {
  background-color: yellow;
}

.sticky_Add_Bom {
  margin-right: 60%;
  margin-top: 20%;
  position: absolute;
}
