.LeftMenu {
  width: 80vh; /* Adjust the width of the menu as needed */
  height: 100vh; /* Set the menu height to fill the viewport */
  background-color: #333;
  box-sizing: border-box;
}

.LeftMenu ul {
  list-style-type: none;
}

.LeftMenu li {
  margin-bottom: 10px;
  color: #ee991b;
  cursor: pointer;
}

.LeftMenu .menu-link {
  color: white;
  text-decoration: none;
}

.LeftMenu li:hover {
  color: #56e35b;
}

.search_render {
  position: relative;
  padding: 10px;
}
