/* Inside the same directory as Modal.js */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: fit-content;
}

.close-button {
  position: absolute;
  top: 11%;
  right: 10%;
  font-size: 40px;
  background: white;
  border: none;
  cursor: pointer;
}

.close-button-report {
  position: absolute;
  top: 11%;
  right: 10%;
  font-size: 40px;
  background: white;
  border: none;
  cursor: pointer;
}

.modal-overlay_order {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_order {
  background: white;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
}
