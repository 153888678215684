.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  padding: 20px;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
}
