/* styles.css */
.image-container {
  position: relative;
  display: inline-block;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.roundedImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
