/* src/LeftMenu.css */
.LeftMenu {
  width: fit-content;
  height: 100vmin;
  background-color: #333;
  margin-right: 5vmin;
  padding: 20px;
  position: sticky;
  margin-top: 0%;
  box-sizing: border-box;
}

.LeftMenu ul {
  list-style-type: none;
  padding: 0;
}

.LeftMenu li {
  margin-bottom: 10px;
  color: #ee991b;
  cursor: pointer;
}

.LeftMenu .menu-link {
  color: white;
  text-decoration: none;
}

.LeftMenu li:hover {
  color: #56e35b;
}
